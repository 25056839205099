import React from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
// import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Team = ({ location }) => {

  const getTeamNames = (node, index) => {
    // console.log(index, node)
    if (node.type === 'tag' && node.name === 'h3') {
      console.log(index, node.children[0].data)
      const name = node.children[0].data
      let id = `/our-team#${node.children[0].data.replace(',', ''). replace('.', '').toLowerCase().replace(' ', '_')}`;
      id = id.split(' ').join('_');
      return <AnchorLink to={id} title={name}><h3>{node.children[0].data}</h3></AnchorLink>
    } else {
      return null
    }
  }

  const addAnchors = (node, index) => {
    if (node.type === 'tag' && node.name === 'h3') {
      console.log(index, node.children[0].data)
      const name = node.children[0].data;
      let id = node.children[0].data.replace(',', ''). replace('.', '').toLowerCase().replace(' ', '_');
      id = id.split(' ').join('_');
      return <h3 id={id}>{name}</h3>
      // return convertNodeToElement(node, index, transform);
    } 
  }
    return (
      <StaticQuery
        query={graphql`
        query {
          wpgraphql {
            page(id: "/our-team/", idType: URI) {
              content
              title
              slug
              uri
            }
          }
        }
        `}
        render={data => 
            <Layout location={location}>
              <SEO
                keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
                title="Our Team"
              />
              <section id="our-team" className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
              <h1 className="text-white font-title font-thin text-4xl mb-5">{data.wpgraphql.page.title}</h1>
              <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(data.wpgraphql.page.content, { transform: getTeamNames }) }</div>
              <div className="text-white h-40"></div>
              <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(data.wpgraphql.page.content, { transform: addAnchors }) }</div>;
              <div>
            </div>
            </section>
            </Layout>
          }
      />
    )  
}

Team.propTypes = {
  location: PropTypes.object
};

export default Team;
